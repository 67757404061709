import React, { useEffect, useState } from "react";
import { Button, Modal, InputGroup, Form } from "react-bootstrap";
import { getAccount } from "../../hooks/useWeb3";
import { OnStake } from "../../hooks/useStake";

const UnlockModal = (props) => {


  const [unlock, setUnlock] = useState(true);
  const [accountInfo, setAccountInfo] = useState("");
  const [isMax, setIsMax] = useState(false);
  const [deposit, setDeposit] = useState("0");
  const [Maxdeposit, setmaxDeposit] = useState("0");
  const [pendingTx, setpendingTx] = useState(false);

  const maxDeposit = () => {
    if (parseFloat(props.userTokenBalance) > 0) {
      setIsMax(true);
      setmaxDeposit(props.userTokenBalance);
    }
  };

  const depositTokens = async () => {
    const pid = props.pid;
    const decimal = props.data.decimals;
    await OnStake(getAccount(), pid, deposit, decimal, isMax);
  };

  useEffect(() => {
    setAccountInfo(getAccount());
    setmaxDeposit(props.userTokenBalance);
  }, []);

  return (
    <>
      <Modal show={unlock} centered size="md text-black">
        <Modal.Header className="pb-0">
          <h5 className="">Deposit {props?.data?.symbol} Tokens</h5>
          <button className="btn btn-link themeclr close" onClick={() => props.onDismiss()}>
            <span className="fa fa-times"></span>
          </button>
        </Modal.Header>
        <Modal.Body className="py-4">
          <p className="text-end themeclr">
            {Maxdeposit && Maxdeposit} {props?.data?.symbol} Available
          </p>
          <InputGroup className="mb-3 h-75">
            <Form.Control
              aria-label="Amount (to the nearest dollar)"
              value={isMax ? Maxdeposit : deposit}
              onChange={(e) => {
                setDeposit(e.target.value);
                setIsMax(false);
              }}
              placeholder="0"
            />
            <InputGroup.Text className="themeclr">
              {props?.data?.symbol}
            </InputGroup.Text>
            <InputGroup.Text>
              <button
                className="chocobtn"
                onClick={() => {
                  setIsMax(true);
                  maxDeposit();
                }}
              >
                MAX
              </button>
            </InputGroup.Text>
          </InputGroup>
          <div className="d-flex justify-content-around mb-4 mt-5">
            <button className="btn getstarted yellowBtn" onClick={() => setUnlock(false)}>
              Cancel
            </button>
            <button className="btn getstarted green-btn" onClick={() => depositTokens()}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UnlockModal;
