import { React, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from '../config/common';
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { registerfun, getAuthToken, PurcahseBot, initpurchaseHistory } from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastAlert } from '../toastalert'

import { getuserPlan, checkuserPlan, getAffilateFee, getPeriods, getRefferAddress } from "../hooks/UseContract";
import { getWalletAddress } from "../lib/localStorage";

function Register() {

  const history = useHistory()
  const dispatch = useDispatch()
  const { plans, Contractplans } = useSelector((state) => state.plan);
  const initialValue = {
    "email": "",
    "password": "",
    "confirmpassword": "",
    'walletaddress': "",
    'referenceCode': ""
  }
  const { walletaddress } = useSelector((state) => (state.wallet))
  const { referenceCode } = useSelector((state) => (state.user))
  const [formValue, setFormValue] = useState(initialValue);
  const [validErrors, setValidErrors] = useState("");

  useEffect(() => {
    if (walletaddress) {
      let formData = { ...formValue, ...{ ['walletaddress']: walletaddress, ['referenceCode']: referenceCode } }
      setFormValue(formData)
    }
  }, [walletaddress])

  const handlechange = (e) => {
    try {
      setValidErrors("");
      const { name, value } = e.target;
      setFormValue((formValue) => ({ ...formValue, [name]: value }));
    } catch (Err) {
      console.log(Err, "handlechange__err");
    }
  };

  const formvalidation = async (data) => {
    try {
      var validationErr = {};
      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
      // let emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;
      if (data.email == "") { validationErr.email = "Email cannot be empty" }
      else if (data.email != "") {
        if (!emailRegex.test(data.email)) { validationErr.email = "Enter valid email" }
      }

      if (!data.password) { validationErr.password = "password cannot be empty" }
      else if ((data.password.length < 6) || (data.password.length > 18)) {
        validationErr.password = "password mmust be minimum 6 and maximum 18"
      } else if (!(passwordRegex.test(data.password))) {
        validationErr.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character"
      }
      if (!data.confirmpassword) { validationErr.confirmpassword = "confirmpassword cannot be empty" }

      return validationErr;
    } catch (Err) {
      console.log(Err, "formvalidation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      var resp = await formvalidation(formValue);
      if (resp) setValidErrors(resp)
      if (!isEmpty(resp)) {
        // console.log("erore", isEmpty(resp))   // shiuld add toastr here
      } else {
        const { status, message } = await registerfun(formValue, dispatch)
        if (status) {
          toastAlert("success", message)
          let account = getWalletAddress()
          let isValid = await checkuserPlan(Contractplans, account)
          console.log(isValid, "handleSubmit")
          if (isValid) {
            for (let i = 0; i < Contractplans.length; i++) {
              let userPlan = await getuserPlan(i, account);
              if (parseFloat(userPlan._buyTime) > 0) {
                // userPlan
                let AffiliateFee = await getAffilateFee()
                let refferAmount = parseFloat(Contractplans[i]._amount) / 10 ** 18 * ((AffiliateFee / 10 ** 18) / 100)
                let compoundingPeriod = await getPeriods(userPlan._periodsID)
                let referaddress = await getRefferAddress(account)
                let historyData = {
                  walletaddress: account,
                  planId: userPlan._planID,
                  fee: parseFloat(Contractplans[i]._amount) / 10 ** 18,
                  period: compoundingPeriod._duration,
                  compoundingPeriodId: userPlan._periodsID,
                  refferAmount: refferAmount,
                  referaddress: referaddress

                }

                let buyPlanData = {
                  walletaddress: account,
                  planId: userPlan._planID,
                  subscriptionFee: parseFloat(Contractplans[i]._amount) / 10 ** 18,
                  buyToken: 'USDT',
                  compoundingPeriodId: userPlan._periodsID,
                  duration: compoundingPeriod._duration,
                }
                await PurcahseBot(buyPlanData)
                await initpurchaseHistory(historyData)
              }
            }
            await getAuthToken({ walletaddress: account }, dispatch)
            // history.push("/dashboard");
            history.push('/launchpad');

          } else {
            history.push('/launchpad');
          }
          // let PlanData  =  await getUserPlan()
          // if(!isEmpty(PlanData)){
          //   history.push('/dashboard')
          // }else{
          //   history.push('/purchase-bot')
          // }
          // setTimeout(() => {
          //   history.push('/dashboard')
          // }, 2000)
        }
        else {
          toastAlert("error", message)
        }
      }
    } catch (e) {
      console.log("submit catch err", e);
    }
  }



  return (

    <>
      {
        console.log('setValidErrors', validErrors)
      }

      <div className="register-page">
        <div className="container py-5">
          <h3 className="header text-center py-4">Register</h3>
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
              <Form>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formValue.email}
                    onChange={(e) => handlechange(e)}
                  />
                  <p className="text-danger mt-2">
                    {validErrors && validErrors.email}
                  </p>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formValue.password}
                    onChange={(e) => handlechange(e)}
                  />
                  <p className="text-danger mt-2">
                    {validErrors && validErrors.password}
                  </p>
                </Form.Group>

                <Form.Group className="mb-5" controlId="formBasicPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="confirmpassword"
                    value={formValue.confirmpassword}
                    onChange={(e) => handlechange(e)}
                  />
                  <p className="text-danger mt-2">
                    {validErrors && validErrors.confirmpassword}
                  </p>
                </Form.Group>
                {/* <Link to="/connect-wallet"> */}
                <Button
                  variant="primary"
                  type="button"
                  size="lg"
                  className="btn common_green_Btn sign-up-btn btn-block w-100"
                  onClick={() => handleSubmit()}
                >
                  Signup
                </Button>
                {/* </Link> */}
              </Form>
              <p className="account-verify text-center mt-3">
                Do you have a account <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
