import React, { useState, useEffect } from "react";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHeader from "./DashboardHeader";
import "react-toastify/dist/ReactToastify.css";
import { RiExchangeDollarLine } from "react-icons/ri";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoIosTimer } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { getChartValues } from "../api/api";
import isEmpty from "../lib/isEmpty";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Statistics() {
  const { Chartdata } = useSelector((state) => state.chart);

  const [sidebar, setSidebar] = useState(false);
  const [chartTab, setChartTab] = useState("profit");

  const [profit, setProfit] = useState([]);
  const [count, setcount] = useState([]);
  const [totalProfit, setTotalProfit] = useState([]);

  const handleChartTab = (val) => {
    setChartTab(val);
  };

  const handleClose = () => {
    setSidebar(!sidebar);
  };

  const updateDimensions = () => {
    // setWidth(window.innerWidth);
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    getChartDatas();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getChartDatasSocket();
    }, 3000);
  }, [Chartdata]);

  const getChartDatasSocket = async () => {
    try {
      let Profit = [];
      let count = [];
      console.log(Chartdata, Chartdata.data, "Chartdata");
      Chartdata?.data?.map((item, i) => {
        console.log(Chartdata.data[0], "ITEMM");
        if (isEmpty(item[0]?.profits)) {
          Profit.push(0);
        } else {
          Profit.push(item[0]?.profits);
        }
        if (isEmpty(item[0]?.count)) {
          count.push(0);
        } else {
          count.push(item[0]?.count);
        }
      });
      setProfit(Profit);
      setcount(count);
      setTotalProfit(Chartdata?.totalProfit[0]?.profits);
    } catch (err) {}
  };
  const getChartDatas = async () => {
    try {
      let { status, message, result, totalProfit } = await getChartValues();
      let Profit = [];
      let count = [];
      result.map((item, i) => {
        if (isEmpty(item[0]?.profits)) {
          Profit.push(0);
        } else {
          Profit.push(item[0]?.profits);
        }
        if (isEmpty(item[0]?.count)) {
          count.push(0);
        } else {
          count.push(item[0]?.count);
        }
      });
      setProfit(Profit);
      setcount(count);
      console.log(totalProfit[0].profits, totalProfit, "totalProfit.profits");
      setTotalProfit(totalProfit[0]?.profits);
    } catch (err) {}
  };

  //   const labels = [
  //     "6.Mar",
  //     "7.Mar",
  //     "8.Mar",
  //     "9.Mar",
  //     "10.Mar",
  //     "11.Mar",
  //     "12.Mar",
  //     "13.Mar",
  //     "14.Mar",
  //     "15.Mar",
  //     "16.Mar",
  //   ];

  //   const data = {
  //     // labels,
  //     datasets: [
  //       {
  //         fill: true,
  //         label: "Dataset 1",
  //         data: [-100, 0, -20, 50, 150, 200, 230, 300, 350, 320, 400],
  //         borderColor: "#26FEB0",
  //         backgroundColor: "#49CE87",
  //         backgroundColor: (context) => {
  //           const ctx = context.chart.ctx;
  //           const gradient = ctx.createLinearGradient(0, 0, 0, 200);
  //           gradient.addColorStop(0, "rgba(38,254,176,0.21)");
  //           gradient.addColorStop(1, "rgba(0,0,0,0)");
  //           return gradient;
  //         },
  //       },
  //     ],
  //   };

  //   const options = {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         // position: 'top' as const,
  //       },
  //       title: {
  //         display: true,
  //         text: "Chart.js Line Chart",
  //       },
  //     },
  //   };

  //   const labels = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //   ];

  //   const data = {
  //     labels,
  //     datasets: [
  //       {
  //         label: "Dataset 1",
  //         data: labels.map(() =>
  //           faker.datatype.number({ min: -1000, max: 1000 })
  //         ),
  //         borderColor: "rgb(255, 99, 132)",
  //         backgroundColor: "rgba(255, 99, 132, 0.5)",
  //       },
  //       {
  //         label: "Dataset 2",
  //         data: labels.map(() =>
  //           faker.datatype.number({ min: -1000, max: 1000 })
  //         ),
  //         borderColor: "rgb(53, 162, 235)",
  //         backgroundColor: "rgba(53, 162, 235, 0.5)",
  //       },
  //     ],
  //   };

  const options = {
    responsive: true,
    plugins: {
      //   legend: {
      //     position: 'top' as const,
      //   },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
      legend: {
        display: false, // Hide the dataset labels
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "black",
          font: {
            size: 10,
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          color: "black",
          font: {
            size: 10,
          },
          callback: (v) => v,
          maxTicksLimit: 7,
        },
      },
      // "right-y-axis": {
      //   type: "linear",
      //   position: "right",
      //   grid: {
      //     display: false,
      //   },
      //   ticks: {
      //     color: "black",
      //     font: {
      //       size: 10,
      //     },
      //     callback: (v) => v,
      //     maxTicksLimit: 7,
      //   },
      // },
    },
  };

  const labels = [
    "00:00",
    "03:00",
    "06:00",
    "09:00",
    "12:00",
    "15:00",
    "18:00",
    "21:00",
    "00:00",
  ];
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Dataset 1",
        data: profit,
        borderColor: "#26FEB0",
        backgroundColor: "#49CE87",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(38,254,176,0.21)");
          gradient.addColorStop(1, "rgba(0,0,0,0)");
          return gradient;
        },
      },
    ],
  };

  return (
    <div className="dashboard statistics">
      <div className="container-fluid d-flex ps-0 ">
        <div className="left_side_sec">
          <DashboardSidebar handleClose={handleClose} sidebar={sidebar} />
        </div>
        <div className="right_side_sec px-4 py-4">
          <DashboardHeader handleClose={handleClose} />
          <div className="content_section mt-5">
            <div className="header-sec">
              <p className="header_txt mb-0">Market Overview</p>
              <div className="day active">24h</div>
            </div>
            <div className="cusTom_LineChart mt-4">
              <p className="graph_title mb-0">
                Performance of MEV Types
                <IoIosInformationCircleOutline className="ms-1" />
              </p>
              <hr className="cus_hr" />
              <div className="pro_val">
                <span
                  className={chartTab == "profit" ? "active" : ""}
                  onClick={() => handleChartTab("profit")}>
                  By Profit
                </span>
                {/* <span
                  className={chartTab == "volumn" ? "active" : ""}
                  onClick={() => handleChartTab("volumn")}>
                  By Volumn
                </span> */}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-3 col-xl-3">
                  <div className="leftSec">
                    <div className="amt_sec mt-4">
                      <p className="mb-3 d-flex align-items-center">
                        <RiExchangeDollarLine
                          size={20}
                          className="me-1 dollarIcon"
                        />
                        <span className="f-13">Arbitrage (24H)</span>
                      </p>
                      <p className="mb-0 amt_val">
                        {chartTab == "profit"
                          ? totalProfit
                            ? totalProfit
                            : 0
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-lg-9 col-xl-9">
                  <div className="rytSec">
                    <div className="custom-bar-graph mt-3 position-relative">
                      <Line options={options} data={data} />
                      <p className="mb-0 profit">
                        {chartTab == "profit" ? "Profit($)" : "Volumn($)"}
                      </p>
                      {/* <p className="mb-0 count">Count</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom_hr" />
              <p className="time mb-0">
                <IoIosTimer size={18} className="pe-1" />2 minutes ago
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
