import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
//import action
import { getPurcahseDetails } from "../api/api";

import moment from "moment";

import { momentFormat } from "../lib/dateTimeHelper";
function TradeHistoryContent() {
  const TradeHistory = [
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "24 hours",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "2 weeks",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "1 month",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "6 month",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "1 year",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "24 hours",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "2 weeks",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "1 month",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "6 month",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "1 year",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "24 hours",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "2 weeks",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "1 month",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "6 month",
      WithdrawlAmount: "100$",
    },
    {
      Date: "13/10/2023",
      Time: "06:00:52 AM",
      PurchaseAmount: "100$",
      PartialAmount: "35$",
      TradeContract: "1 year",
      WithdrawlAmount: "100$",
    },
  ];

  const { plans } = useSelector((state) => state.plan);
  useEffect(() => {
    HistoryDetails();
  }, []);
  const [purchaseDetails, setpurchaseDetails] = useState([]);
  const HistoryDetails = async () => {
    try {
      const { result, status } = await getPurcahseDetails();
      if (status) {
        setpurchaseDetails(result);
      }
    } catch (err) {
      console.log(err, "HistoryDetails___err");
    }
  };
  return (
    <div className="trade-history-table-sec table_new">
      <Table bordered hover responsive className="text-center table_cont">
        <thead>
          <tr>
            <th>Date & Time</th>
            <th>Bot Purchased</th>
            <th>Bot name</th>
            {/* <th>Trading Contract</th>
            <th>Withdrawal Amount</th> */}
          </tr>
        </thead>
        <tbody>
      
          {purchaseDetails && purchaseDetails?.length > 0
            ? purchaseDetails.map((item) => {
                let planData = plans.find((val) => val?._id == item?.planId);
                return (
                  <>
                    <tr>
                      <td>
                        {momentFormat(item?.createdDate, "DD-MM-YYYY HH:mm")}
                        <br />
                      </td>
                      <td>$ {item?.subscriptionFee}</td>
                      <td>{planData?.name}</td>
                      {/* <td>{item.TradeContract}</td>
                  <td>{item.WithdrawlAmount}</td> */}
                    </tr>
                  </>
                );
              })
            : ""}
        </tbody>
      </Table>
    </div>
  );
}

export default TradeHistoryContent;
