// import constant
import {
    PLANS,
    CONTRACT_PLANS
} from '../constant';

const initialValue = {
    plans:[]
}

//  console.log("initialValue----->>>",initialValue);
const plan = (state = initialValue, action) => {

    switch (action.type) {
        case PLANS:
            return {
                ...state,
                plans: action.payload
            }
            case CONTRACT_PLANS:
            return {
                ...state,
                Contractplans: action.payload
            }
        default:
            return state;
    }
}

export default plan;