import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { getReffer, getUserBycode } from "../api/api";
import { getOwner, sleep } from "../hooks/UseContract";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AffiliateModal(props) {
  const history = useHistory();

  const [affiliateModal, setAffiliateModal] = useState(true);

  const getreffer = async () => {
    try {
      // let affiliateCode = props.affiliateCode
      // await getReffer({ code: affiliateCode })
      let userdata = await getUserBycode({ referralCode: props.affiliateCode });
      console.log("userdata?.status", userdata?.status === "success" && userdata?.result?.walletaddress == props.affiliateCode);
      if ((userdata?.status === "success") && (userdata?.result?.walletaddress == props.affiliateCode)) {
        localStorage.setItem("referrerFeeAccount", props.affiliateCode)


        sleep(1000)
        history.push("/connect-wallet");


      } else {
        let adminWalletAddress = await getOwner();

        localStorage.setItem("referrerFeeAccount", adminWalletAddress)
        toast.error("This Address not exist")
      }

      console.log("1UserDatabyCodestatus>>>>>>>", userdata);
    } catch (err) {
      console.log("getReffer__err", err)
    }
  }

  useEffect(() => {
    UserDatabyCode()
  })

  const UserDatabyCode = async () => {


    console.log("UserDatabyCodestatus>>>>>>>>", props.affiliateCode);
    let userdata = await getUserBycode({ referralCode: props.affiliateCode });
    console.log("1UserDatabyCodestatus>>>>>>>", userdata?.result?.walletAddress);
    let adminWalletAddress = await getOwner();
    // const referrerAddress = referrerId ? userdata?.result?.walletAddress : adminWalletAddress;
    // localStorage.setItem("referrerFeeAccount", referrerAddress)
  };
  // const{walletData}=useSelector((state)=>(state.wallet))
  return (
    <>
      <Modal
        className="purchase_bot_modal"
        centered
        show={affiliateModal}
        onHide={() => { props.onDismiss() }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Affiliate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="modal_popup_card">
            <Card.Body>
              <div className="user_details mb-3">
                <div className="row">
                  {/* <div className="col-4 d-flex align-items-center">
                    <p className="mb-0">Current Email</p>
                  </div> */}

                  {/* <div className="col-8">
                    <div className="current_email">
                      <p className="mb-0">{formValue.email}</p>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 d-flex align-items-center ">
                    <p className="mb-0">Enter Affiliate Address</p>
                  </div>

                  <div className="col-12 col-sm-12 mt-3 mt-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="affiliatecode"
                      id="affiliatecode"
                      placeholder="Enter your affiliate Address"
                      onChange={(e) => {
                        const { value, name } = e.target
                        props.setAffiliateCode(value)
                      }}
                    />
                  </div>
                </div>
                <p className="validErrors_style mt-3">
                  {/* {validErrors && validErrors.name} */}
                </p>
              </div>
              <div className="d-flex justify-content-end">
                {/* <Link to="/connect-wallet"
                  variant="primary"
                  type="button"
                  size="md"
                  className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                  onClick={() => {
                    getreffer()
                    props.onDismiss()
                  }}
                > */}
                <button className="green-btn mob_btn register_custom_btn" onClick={() => {
                  getreffer()
                  props.onDismiss()
                }}>Submit</button>
                {/* </Link> */}
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AffiliateModal;
