import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import icon from "../Assets/bitcoin.png";

import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { key } from "../config/config";
import qrcode from "../Assets/qrcode.png";
import QRCode from "react-qr-code";
import isEmpty from "../lib/isEmpty";

import { toastAlert } from "../lib/toastAlert";

import { withDrawRequest } from "../api/api";
function WalletContent() {
  const [Qrshow, setQrShow] = useState(false);
  const [withDrawDisable,setwithDrawDisable] = useState(false)
  const [formValue, setFormValue] = useState({});
  const [DepositCurrency, setDepositCurrency] = useState({});
  const [withdrawData, setwithdrawData] = useState({});
  const [withdrawCurrency, setwithdrawCurrency] = useState({});
  const [errors, setErrors] = useState({});
  const handleShowQr = (currencyData) => {
    setQrShow(true);
    setDepositCurrency(currencyData);
  };
  const handleCloseQr = () => setQrShow(false);

  const [withdrawshow, setWithdrawShow] = useState(false);

  const handleShowWithdraw = (data, currency) => {
    setWithdrawShow(true);
    setwithdrawData(data);
    setErrors({})
    setwithdrawCurrency(currency)
  };
  const handleCloseWithdraw = () => {
    setWithdrawShow(false);
    setFormValue({})
  };

  const { walletData } = useSelector((state) => state.wallet);

  const { currencyDetails } = useSelector((state) => state.currency);

  const handlechange = (e) => {
    try {
      setErrors({})
      var { name, value } = e.target;
      console.log("jhdfjhasjf", e.target.name);
      var numbers = /^\d+((.)|(.\d{0,4})?)$/;

      console.log(["twofa", 'amount'].includes(name) && !numbers.test(value), "regex")
      // if (["twofa", 'amount'].includes(name) && !numbers.test(value)) {
      //   console.log(["twofa", 'amount'].includes(name) && !numbers.test(value), "regex1")
      //   return false;
      // }

      //   if (name == "toAddress") {
      //     console.log("enter");
      //    if (['toAddress'].includes(name) && !addressRegex.test(value)) {
      //     return false;
      //   }
      // }

      if (name == 'amount') {
        if (value) {
          let perecn = parseFloat(value) * (parseFloat(withdrawCurrency.withdrawFee) / 100)
          let amountwithFee = parseFloat(value) + parseFloat(perecn)
          setFormValue((formValue) => ({ ...formValue, ...{ [name]: value, 'amountwithFee': amountwithFee } }));
        } else {
          setFormValue((formValue) => ({ ...formValue, [name]: value }));
        }

        return true
      }else{
        setFormValue((formValue) => ({ ...formValue, [name]: value }));
      }

      
    } catch (err) {
      console.log(err, "handlechange__err");
    }
  };

  const handleSubmitWithdraw = async () => {
    console.log("enterrrrr", formValue);
    try {
      setwithDrawDisable(true)
      let isValid = validation(formValue)

      if (isEmpty(isValid)) {
        let data = { ...formValue, ...{ ["currencySymbol"]: withdrawData?.currencySymbol } }
        const { result, status, message } = await withDrawRequest(data)
        if (status) {
          toastAlert("success", message, "handleSubmitWithdraw")
          setwithDrawDisable(false)
          handleCloseWithdraw()
        } else {
          setwithDrawDisable(false)
          toastAlert("error", message, "handleSubmitWithdraw")
        }
      } else {
        return false
      }
    } catch (err) {
      console.log(err, "handleSubmitWithdraw__err")
    }
  }

  const validation = (data) => {
    var addressRegex = "^(0x)[0-9A-Fa-f]{40}$";
    try {
      let error = errors
      if (isEmpty(data.amount)) {
        error = { ...error, ...{ 'amount': "Amount should not be empty" } }
      }
      if (isEmpty(data.toAddress)) {
        console.log("empty");
        error = { ...error, ...{ 'toAddress': "Address should not be empty" } }
      }
      else if (!data.toAddress?.match(addressRegex)) {
        console.log("notempty",!data.toAddress?.match(addressRegex));
        error = { ...error, ...{ 'toAddress': "Invalid address format" } }
      }
      if (isEmpty(data.twofa)) {
        error = { ...error, ...{ 'twofa': "Twofa should not be empty" } }
      }
      setErrors(error)
      return error
    } catch (err) {
      console.log(err, "validation__err")
    }
  }
  console.log("formValue", formValue);

  const copyWalletAddress = "mksjmo8MCKsWjygFvHsSgFC77";
  return (
    <div className="wallet-content">
      <div className="row">
        <div className="col-xl-12 col-xxl-12 mb-3">


          {/* React Bootstrap Table */}

          <div className="trade-history-table-sec table_new">
            <Table bordered hover responsive className="text-center table_cont">
              <thead>
                <tr>
                  <th>Icon</th>
                  <th>Name</th>
                  <th>Balance</th>
                  <th>Deposit</th>
                  <th>Withdraw</th>
                </tr>
              </thead>
              <tbody>
                {walletData && walletData?.assets?.length > 0
                  ? walletData?.assets?.map((val, i) => {
                    var currencyData =
                      currencyDetails && currencyDetails?.length > 0
                        ? currencyDetails?.find(
                          (vals) =>
                            vals?.currencySymbol == val?.currencySymbol
                        )
                        : {};
                    console.log(currencyData, "currencyData")
                    return (
                      <tr>
                        <td>
                          {" "}
                          {currencyData?.currencyImage ? (
                            <img
                              src={`${key?.BACK_URL}/public/currency/${currencyData?.currencyImage}`}
                              // src={icon}
                              alt="icon_logo"
                              className="img-fluid icon_logo"
                            />
                          ) : (
                            <img
                              src={icon}
                              alt="icon_logo"
                              className="img-fluid icon_logo"
                            />
                          )}
                        </td>
                        <td>{val?.currencySymbol}</td>
                        <td>{parseFloat(val?.balance).toFixed(2)}</td>
                        <td>
                          <Button
                            className="common_green_Btn btn_fnt-sz deposit_btn"
                            onClick={() => {
                              handleShowQr(currencyData);
                            }}
                            disabled={currencyData?.depositStatus == 'On' ? false : true}
                          >
                            Deposit
                          </Button>
                        </td>

                        <td>
                          <Button
                            className="common_green_Btn btn_fnt-sz"
                            onClick={() => {
                              handleShowWithdraw(val, currencyData);
                            }}
                            disabled={currencyData?.withdrawStatus == 'On' ? false : true}
                          >
                            Withdraw
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                  : ""}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* Deposit */}

      {/* <Modal
        className="qr_code_modal"
        centered
        show={Qrshow}
        onHide={handleCloseQr}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bitcoin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="deposit_modal">
            <div className="d-flex justify-content-center align-items-center">
              <QRCode
                title="GeeksForGeeks"
                value={walletData.walletAddress}
              // bgColor={back}
              // fgColor={fore}
              // size={size === '' ? 0 : size}
              />
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <ul>
                <li>Lorem Ipsum is simply dummy text.</li>
                <li>Lorem Ipsum is simply dummy text.</li>
                <li>Lorem Ipsum is simply dummy text.</li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseQr} className="common_green_Btn">
            Done
          </Button>
        </Modal.Footer>
      </Modal> */}
      
      {/*Deposit*/}
      <Modal
        className="qr_code_modal modal_container"
        centered
        show={Qrshow}
        onHide={handleCloseQr}
      >
        <Modal.Header closeButton>
          <Modal.Title>Crypto Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body_ht">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Deposit Currency</Form.Label>
              <Form.Control
                type="text"
                placeholder="BTC"
                value={DepositCurrency?.currencySymbol}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Your BTC Wallet Address</Form.Label>
              {/* <Form.Control type="text" placeholder="mksjmo8MCKsWjPhCPanWdrygFvHsSgFC77" /> */}
              <div className="copy_input">
                <div className="row copy_content align-items-center">
                  <div className="col-10">
                    <p className="mb-0 wallet-adrs">
                      {walletData.walletAddress && walletData.walletAddress.slice(0, 7)}...
                      {walletData.walletAddress && walletData.walletAddress.slice(
                        walletData.walletAddress.length - 9,
                        walletData.walletAddress.length - 1
                      )}
                    </p>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <div
                      className="copy_btn"
                      onClick={() => {
                        navigator.clipboard.writeText(walletData.walletAddress);
                        toastAlert(
                          "success",
                          "wallet address Copied Successfully",
                          {
                            style: {
                              minWidth: "300px",
                              minHeight: "55px",
                            },
                          }
                        );
                      }}
                    >
                      <i class="fa-regular fa-copy"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form>

          <div className="scan_qr d-flex flex-column align-items-center">
            <p>Scan QR Code</p>
            <QRCode
              title="GeeksForGeeks"
              value={walletData.walletAddress}
              className="qr_bar_code"
            // bgColor={back}
            // fgColor={fore}
            // size={size === '' ? 0 : size}
            />
          </div>

          <div className="d-flex flex-column align-items-start mt-3">
            <h6>Notes</h6>

            <div className="mb-1 text-secondary d-flex align-items-start">
              <p className="mb-0 me-2">1.</p>
              <p className="mb-0 ">Deposit fee {DepositCurrency?.depositFee}%</p>
            </div>
            {/* <div className="mb-1 text-secondary d-flex align-items-start">
              <p className="mb-0 me-2">2.</p>
              <p className="mb-0 ">
                Deposit minimum deposit limit or greaterthan deposit limit
              </p>
            </div> */}
            {/* <div className="mb-1 text-secondary d-flex align-items-start">
              <p className="mb-0 me-2">3.</p>
              <p className="mb-0 ">
                Deposit time will take 4-24 hours due to bank network
              </p>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* Withdraw */}
      <Modal
        className="withdraw_modal modal_container"
        centered
        show={withdrawshow}
        onHide={handleCloseWithdraw}
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Crypto</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body_ht">
          <div className="row">
            <div className="col-12 col-sm-6 mb-4">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Amount</Form.Label>
                <div className="copy_input input_with_txt">
                  <div className="row mx-auto">
                    <div className="col-8 copy_content d-flex align-items-center py-0 cus_inpt_scl_rem"  >
                      <input type="number"
                        placeholder="Enter amount"
                        value={formValue?.amount}
                        name='amount'
                        onChange={(e) => handlechange(e)}
                        onWheel={(e) => e.target.blur()}

                      />
                    </div>
                    <div className="col-4 copy_content d-flex align-items-center justify-content-center input_txt_bg py-0">
                      {withdrawData && withdrawData?.currencySymbol}
                    </div>
                  </div>
                  {/* <p className="copy_content d-flex align-items-center justify-content-end fnt_itl mb-0">
                    BTC
                  </p> */}
                </div>
                <p className="text-danger mt-2">{errors && errors.amount}</p>
              </Form.Group>
            </div>
            <div className="col-12 col-sm-6 mb-4">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Withdraw Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="toAddress"
                  value={formValue.toAddress}
                  onChange={(e) => handlechange(e)}
                />
                {/* <input type="text" name="toAddress" className="copy_input wth_drw_inpt" /> */}
              </Form.Group>
              <p className="text-danger mt-2">{errors && errors.toAddress}</p>
            </div>
            <div className="col-12 mb-4">
              <div className="wallet_bal d-flex align-items-center justify-content-center">
                <p className="mb-0">
                  Wallet Balance{" "}
                  <span>
                    {parseFloat(withdrawData.balance).toFixed(2)} {withdrawData?.currencySymbol}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 mb-4">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Withdraw Amount with Fee</Form.Label>
                <div className="copy_input input_with_txt">
                  <div className="row mx-auto">
                    <div className="col-8 copy_content d-flex align-items-center py-0">
                      {/* <input type="text" placeholder="0" value={formValue?.amountwithFee} /> */}
                      {/* <Form.Control
                        type="number"
                     
                        value={formValue?.amountwithFee}
                      // onChange={(e) => handlechange(e)}
                      /> */}
                      <p className="mb-0">{formValue?.amountwithFee}</p>
                    </div>
                    <div className="col-4 copy_content d-flex align-items-center justify-content-center input_txt_bg py-0">
                      {withdrawData && withdrawData?.currencySymbol}
                    </div>
                  </div>
                  {/* <p className="text-danger mt-2">{errors && errors.twofa}</p> */}
                  {/* <p className="copy_content d-flex align-items-center justify-content-end fnt_itl mb-0">
                    BTC
                  </p> */}
                </div>
              </Form.Group>
            </div>
            <div className="col-12 col-sm-6 mb-4">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Enter 2FA Code</Form.Label>
                <Form.Control
                  type="number"
                  // placeholder="2 X Y 4 b"
                  name="twofa"
                  value={formValue.twofa}
                  onChange={(e) => handlechange(e)}
                />
              </Form.Group>
              <p className="text-danger mt-2">{errors && errors.twofa}</p>
            </div>
            <div className="col-12 done_btn">
              <Button
                onClick={() => { handleSubmitWithdraw() }}
                className="common_green_Btn w-100"
                disabled ={withDrawDisable}
              >
                Done
              </Button>
            </div>
            <div className="d-flex flex-column align-items-start mt-3">
              <h6 className="mt-3">Notes</h6>

              <div className="mb-1 text-secondary d-flex align-items-start">
                <p className="mb-0 me-2">1.</p>
                <p className="mb-0 ">Withdraw fee {withdrawCurrency.withdrawFee} %</p>
              </div>
              {/* <div className="mb-1 text-secondary d-flex align-items-start">
                <p className="mb-0 me-2">2.</p>
                <p className="mb-0 ">
                  Submit your withdraw ,you Received mail can approve (or)
                  cancel
                </p>
              </div> */}
              {/* <div className="mb-1 text-secondary d-flex align-items-start">
                <p className="mb-0 me-2">3.</p>
                <p className="mb-0 ">Your withdraw valid only for 2 minutes</p>
              </div> */}
              {/* <div className="mb-1 text-secondary d-flex align-items-start">
                <p className="mb-0 me-2">4.</p>
                <p className="mb-0">
                  After 2 minutes your withdraw not completed automatically
                  cancelled and then your amount will be updated
                </p>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default WalletContent;
