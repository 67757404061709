import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  InputGroup,
  Dropdown,
  Form,
  ProgressBar,
} from "react-bootstrap";
import {
  approveContract,
  BuyToken,
  checkIsApproved,
  Claim,
  getCrypto,
  UseBuyToken,
  UsegetSaleInfo,
  UsergetToken,
} from "../../hooks/UseContract";

import { getAccount } from "../../hooks/useWeb3";
import CustomLoader from "../CustomLoader";
import isEmpty from "../../lib/isEmpty";
import { launchpadList } from "../../../../Admin/src/actions/launchPad";
import { AdminRefferAddress } from "../../config/config";
import { toast } from "react-toastify";

const Masp = (props) => {
  const [masp, setMasp] = useState(true);
  const [LaunchpadData, setLaunchpadData] = useState({});
  const [presaleaddress, setpresaleaddress] = useState(props?.presaleaddress);
  const [accountInfo, setAccountInfo] = useState(getAccount());
  // const [accountInfo, setAccountInfo] = useState();
  const [isMax, setIsMax] = useState(false);
  const [Maxdeposit, setmaxDeposit] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [userWillget, setuserWillget] = useState(0);
  const [progressBNB, setprogressBNB] = useState(0);
  const [progressUSDT, setprogressUSDT] = useState(0);
  const [presaletoken, setpresaletoken] = useState(0);
  const [allowance, setallowance] = useState(0);
  const [tokensymbol, settokensymbol] = useState("");
  const [maxBalance, setmaxBalance] = useState(0);
  const [coinlist, setCoinlist] = useState("BNB")
  const [price, setprice] = useState(0)
  const [min, setmin] = useState(0)
  const [max, setmax] = useState(0)
  const [hardCap, sethardCap] = useState(0)
  const [earnedCap, setearnedCap] = useState(0)
  const [userTokenBalance, setuserTokenBalance] = useState(0)
  const [Userinvestment, setUserinvestment] = useState(0)



  useEffect(() => {
    setpresaleaddress(getAccount());
    getData(props?.presaleaddress);
  }, [presaleaddress]);

  const getData = async (value) => {
    try {
      var data =
        presaleaddress && value != ""
          ? await UsegetSaleInfo(value, accountInfo)
          : "";
      console.log(data, "getDatagetData");
      approveFunction(data?.usdtaddress, props?.presaleaddress);
      setLaunchpadData(data);
      var progressbnb = (
        (parseInt(data?.bnbearnedcap) / parseInt(data?.hardCap)) *
        100
      ).toFixed(2);
      var progressUSDT = (
        (parseInt(data?.busdearnedcap) / parseFloat(data?.busdhard)) *
        100
      ).toFixed(2);
      setprogressBNB(progressbnb);
      setprogressUSDT(progressUSDT);
      setpresaletoken(data?.presaletoken);
      setallowance(data?.allowance);
      settokensymbol(data?.presaletokensymbol);
    } catch (e) {
      console.log("getData", e);
    }
  };






  const maxDeposit = () => {

    console.log("coinlist == BNB", coinlist);

    if (coinlist == "BNB" && parseFloat(LaunchpadData && LaunchpadData?.UserBNB) > 0) {
      setIsMax(true);
      handleChange(
        LaunchpadData && LaunchpadData?.UserBNB
      );
      setmaxDeposit(
        LaunchpadData && LaunchpadData?.UserBNB
      );
      setmaxBalance(LaunchpadData && LaunchpadData?.UserBNB)
    } else {
      setIsMax(true);
      handleChange(Math.round(userTokenBalance));
      setmaxDeposit(userTokenBalance);
      setmaxBalance(userTokenBalance)
    }
  }
  const approveFunction = async (value) => {
    const approval = await checkIsApproved(
      accountInfo,
      value,
      props?.presaleaddress
    );
    setIsApproved(approval);
  };

  const approve = async () => {
    if (accountInfo) {
      await approveContract(
        accountInfo,
        LaunchpadData && LaunchpadData?.usdtaddress,
        props?.presaleaddress
      );
      setIsApproved(true);
    }
  };

  const handleChange = async (data) => {
    var price = await UsergetToken(
      data,
      coinlist,
      LaunchpadData?.presaletoken,
      props?.presaleaddress
    );

    console.log("price>>>>>,", price);
    setuserWillget(price);
  };

  const claimToken = async () => {
    await Claim(accountInfo, props?.presaleaddress)
      .then(async (result) => {
        getData(props?.presaleaddress);
      })
      .catch((e) => {
        console.log("Error");
      });
  };

  const buyToken = async () => {

    // if ((deposit <= 0) || (Maxdeposit <= 0)) {

    //   toast.error("Amount field must required or not equal to zero")

    // } else {
    let refferAddress = localStorage.getItem("referrerFeeAccount")
    var data = await UseBuyToken(
      refferAddress,
      coinlist,
      deposit > 0 ? deposit : maxBalance,
      presaletoken,
      props?.presaleaddress,
      accountInfo,
      isMax
    );

    getData(props?.presaleaddress);
    // }

  };



  const getCryptoInfo = async (vaue) => {
    let info = await getCrypto(props?.presaleaddress, vaue, accountInfo);
    console.log(info, "infoinfoinfo");
    setprice(info?.price)
    setmin(info?.minLimit)
    setmax(info?.maxLimit)
    sethardCap(info?.hardCap)
    setearnedCap(info?.earnedCap)
    setuserTokenBalance(info?.userBalance)


  }
  return (
    <>
      <Modal show={masp} centered size="xl" className="text-black">
        <Modal.Header className="pb-0">
          <h5 className=""> {tokensymbol} </h5>
          <button
            className="btn btn-link themeclr close"
            onClick={() => props.onDismiss()}>
            <span className="fa fa-times"></span>
          </button>
        </Modal.Header>
        <Modal.Body className="py-4">
          <>


            {console.log(isEmpty(LaunchpadData), "LaunchpadData+++++++++")}
            {!isEmpty(LaunchpadData) ? (
              <>

                <ul className="mb-0 coinul mt-4">
                  <li className={coinlist === "BNB" ? "active" : ""} onClick={() => setCoinlist("BNB")}>BNB</li>
                  <li className={coinlist === "USDT" ? "active" : ""} onClick={() => { setCoinlist("USDT"); getCryptoInfo("USDT") }}>USDT</li>
                  <li className={coinlist === "BUSD" ? "active" : ""} onClick={() => { setCoinlist("BUSD"); getCryptoInfo("BUSD") }}>BUSD</li>
                </ul>
                <div className="d-lg-flex gap-5 flex-1 my-4">

                  <div className="mb-4">
                    <div className="d-flex jc-between">
                      <p>
                        {" "}
                        Progress (
                        {coinlist == "BNB" ? (LaunchpadData && LaunchpadData?.bnbearnedcap) : (earnedCap) || 0}{" "} /
                        {coinlist == "BNB" ? (LaunchpadData && LaunchpadData?.hardCap) : (hardCap) || 0}{" "}
                        {coinlist == "BNB" ? "BNB" : coinlist == "USDT" ? "USDT" : "BUSD"} ){" "}
                      </p>
                      <p>            {coinlist == "BNB" ? (parseInt(LaunchpadData?.bnbearnedcap) / parseInt(LaunchpadData?.hardCap)) * 100 :
                        ((parseInt(earnedCap) / parseInt(hardCap)) * 100) || 0
                      } %
                      </p>
                    </div>
                    <ProgressBar now={(parseInt(coinlist == "BNB" ? (LaunchpadData && LaunchpadData?.bnbearnedcap) : (earnedCap) || 0) / parseInt(coinlist == "BNB" ? (LaunchpadData && LaunchpadData?.hardCap) : (hardCap) || 0)) * 100}
                    />
                  </div>
                </div>

                <div className="yellowbox text-center p-4 my-4">
                  <div className="d-lg-flex jc-between align-items-center  flex-1">
                    <div className="d-md-flex justify-content-lg-around gap-3 align-items-center w-full ">
                      <div className="text-start">
                        <p className="themeclr mb-1 normal">Your Balance</p>
                        <h6 className="bold">
                          {(coinlist == "BNB" ?
                            (parseFloat(
                              LaunchpadData?.UserBNB
                            ).toFixed(3)) : (parseFloat(
                              userTokenBalance
                            ).toFixed(3))) ||
                            0}{" "}
                          {coinlist == "BNB" ? "BNB" : coinlist == "USDT" ? "USDT" : "BUSD"}
                        </h6>
                      </div>
                      <div className="text-start">
                        <p className="themeclr mb-1 normal">
                          Your Minimum Contribution
                        </p>
                        <h6 className="bold">
                          {coinlist == "BNB" ? (LaunchpadData && LaunchpadData?.minamount) : (min) || 0}{" "}

                          {coinlist == "BNB" ? "BNB" : coinlist == "USDT" ? "USDT" : "BUSD"}
                        </h6>
                      </div>
                    </div>
                    <div className="d-md-flex justify-content-lg-around gap-3 align-items-center w-full ">
                      <div className="text-start">
                        <p className="themeclr mb-1 normal">
                          Your Maximum Contribution
                        </p>

                        <h6 className="bold">
                          {coinlist == "BNB" ? (LaunchpadData && LaunchpadData?.maxamount) : (max) || 0}{" "}

                          {coinlist == "BNB" ? "BNB" : coinlist == "USDT" ? "USDT" : "BUSD"}
                        </h6>
                      </div>

                      <div className="text-start">
                        <p className="themeclr mb-1 normal">
                          Your Contribution

                        </p>

                        <h6 className="bold">
                          {" "}
                          {coinlist == "BNB" ? parseFloat(
                            LaunchpadData && LaunchpadData?.investedT
                          ).toFixed(6) : Userinvestment.toFixed(6) || 0}  {" "}
                          {coinlist == "BNB" ? "BNB" : coinlist == "USDT" ? "USDT" : "BUSD"}
                        </h6>
                      </div>
                      <div className="text-start">
                        <p className="themeclr mb-1 normal">You will Get</p>
                        <h6 className="bold">
                          {userWillget || 0} {tokensymbol}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-lg-flex gap-5 flex-1 mt-4">
                  <div className="mb-4">
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="Enter Amount"
                        onChange={(e) => {
                          setDeposit(e.currentTarget.value);
                          setIsMax(false);
                          handleChange(e.currentTarget.value);
                        }}
                        value={isMax ? Maxdeposit : deposit}
                      />

                      <InputGroup.Text>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="dropdown-basic"
                            className="chocobtn text-dark nounder">
                            {coinlist}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            { }
                            <Dropdown.Item
                              onClick={() => {
                                setCoinlist("BNB");
                              }}>
                              {" "}
                              BNB
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setCoinlist("USDT"); getCryptoInfo("USDT")
                              }}>
                              {" "}
                              USDT
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                setCoinlist("BUSD"); getCryptoInfo("BUSD")
                              }}>
                              {" "}
                              BUSD
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="d-flex justify-content-start gap-4 mt-4">
                      {isApproved ? (
                        <button
                          className="btn getstarted"
                          onClick={() => {
                            setIsMax(true);
                            maxDeposit();
                          }}>
                          Max
                        </button>
                      ) : (
                        <></>
                      )}
                      {isApproved ? (
                        <>

                          {(coinlist == "BNB" || coinlist == "USDT" || coinlist == "BUSD") && (parseFloat(LaunchpadData?.UserBNB) > 0) || parseFloat(userTokenBalance > 0) ?
                            <button
                              className="btn getstarted"
                              onClick={() => buyToken()}>
                              Buy
                            </button> :
                            <button
                              className="btn getstarted"
                              disabled={true}>
                              Buy
                            </button>
                          }
                        </>

                      ) : (
                        <button
                          className="btn getstarted"
                          onClick={() => approve()}>
                          Approve
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <InputGroup className="mb-3">
                      <Form.Control
                        value={LaunchpadData && LaunchpadData?.claimb}
                        aria-label="Amount (to the nearest dollar)"
                        disabled
                        placeholder="0"
                      />
                      <InputGroup.Text className="themeclr">
                        {tokensymbol}
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="d-flex justify-content-start  mt-4">
                      {LaunchpadData &&
                        parseFloat(LaunchpadData?.claimb) > 0 ? (
                        <button
                          className="btn getstarted"
                          onClick={() => claimToken()}>
                          Claim
                        </button>
                      ) : (
                        <button disabled className="btn getstarted">
                          Claim
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="launchModal_loader">
                <CustomLoader />
              </div>
            )
            }
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Masp;
