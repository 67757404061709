import React, { useState, useEffect } from "react";
import classnames from "classnames";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import icon from "../Assets/bitcoin.png";

import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { key } from '../config/config';
import { get2FA, Enable2FA, Disable2FA, profileSettings } from "../api/api";

import { toastAlert } from "../lib/toastAlert";

import CopyToClipboard from 'react-copy-to-clipboard'
import { isEmpty } from "../config/common";
import qrcode from "../Assets/qrcode.png";
import QRCode from "react-qr-code";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaCopy } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { twoFAStatus } from '../lib/displayStatus'
const initailValue = {
    code: "",
    newpassword: "",
    confirmnewpassword: "",
    password: ""
}
function SecurityContent() {
    const [secretData, setSecretData] = useState()
    const [password, setPassword] = useState(true)
    const [newpassword, setNewPassword] = useState(true)
    const [confirmnewpassword, setConfirmNewPassword] = useState(true)
    const [formValue, setFormValue] = useState(initailValue);

    const [code, setCode] = useState()
    const [validateError, setValidateError] = useState({});

    const { userDetails } = useSelector((state) => state.user)
    console.log("userDetails", userDetails.email);

    useEffect(() => {
        fetchTwoFA()
    }, [])
    const fetchTwoFA = async () => {
        try {
            const { status, loading, message, result } = await get2FA();
            if (status) {
                setSecretData(result)
            }
        } catch (err) {
            console.log(err, "fetchTwoFA__err")
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
            return
        }
        setCode(value)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }
    const enable2fa = async () => {
        try {
            let data = {
                secret: secretData.secret,
                uri: secretData.uri,
                code: code
            }
            const { status, result, message } = await Enable2FA(data)
            if (status) {
                toastAlert('success', message, 'twoFA')
                setCode('')
                setSecretData(result)
            }
            else {
                // if (error) {
                //     this.setState({ errors: error });
                //   }
                toastAlert('error', message)
            }
        } catch (err) {
            console.log(err, "enabledT2fa")
        }
    }
    const disable2fa = async () => {
        try {
            let data = {
                secret: secretData.secret,
                uri: secretData.uri,
                code: code
            }
            const { status, result, message } = await Disable2FA(data)

            if (status) {
                toastAlert('success', message)
                setCode('')
                setSecretData(result)
            }
            else {
                // if (error) {
                //     this.setState({ errors: error });
                //   }
                toastAlert('error', message)
            }
        } catch (err) {
            console.log(err, "enabledT2fa")
        }
    }

    const copyToClipboard = () => {
        // alert(secretData?.secret)
        copy(secretData?.secret);
        toast.success("Security code copied Successfully"
            //  {
            //   style: {
            //     minWidth: '300px',
            //     minHeight: '55px'
            //   }
            // }
        )

    }


    const handlechange = (e) => {
        setValidateError({})
        const { name, value } = e.target;
        setFormValue((formValue) => ({ ...formValue, [name]: value }));

    };
    //   console.log("hhhhhhyhy",formValue);
    const formvalidation = async (data) => {
        var validateError = {};
        var regex = /^[a-zA-Z0-9!@#$%^&*]{6,18}$/
        if (data.newpassword == "") {
            validateError.newpassword = "new password cannot be empty";
        } else {
            if (isEmpty(data.newpassword?.match(regex))) {
                validateError.newpassword = "Invalid format"
            }
        }
        if (data.confirmnewpassword == "") {
            validateError.confirmnewpassword = "Confirm password cannot be empty";
        }
        else {
            if (isEmpty(data.confirmnewpassword?.match(regex))) {
                validateError.confirmnewpassword = "Invalid format"
            }
        }
        if (data.password == "") {
            validateError.password = "Password cannot be empty";
        }
        return validateError;
    };
    const handleSubmit = async () => {
        var resp = await formvalidation(formValue);
        if (resp) setValidateError(resp);
        if (!isEmpty(resp)) {
            // console.log("erore", isEmpty(resp))   // shiuld add toastr here
        } else {
            console.log("formValue", formValue);
            var data = {
                newpassword: formValue.newpassword,
                confirmnewpassword: formValue.confirmnewpassword,
                password: formValue.password,
                email: userDetails.email
            }
            const { status, loading, message, error } = await profileSettings(data);
            console.log("message", message);
            if (status) {
                // fetchData();
                toastAlert("success", message);
                setNewPassword('')
                setConfirmNewPassword('')
                setPassword('')
                setValidateError({})
            } else {

                toastAlert("error", message);
            }

        }
    };
    return (
        <div className="security-settings">
            <h3 className="my-3">Security Settings</h3>
            <div className="row">
                <div className="col-xl-12 mb-3">
                    <Card className="settings_card">
                        <Card.Body className="">
                            <div className="tab_content_sec">
                                <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="home" title="2 Step Authentications">
                                        <div className="inside_content authentication_factor">
                                            <div className="row">
                                                <div className="col-xl-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
                                                    <div className="two_factor_authentication_content">
                                                        <p className="enabled_status">
                                                            2 Factor Authentication{" "}
                                                            <span className="status">Status</span> -{" "}
                                                            <span className="enabled">Enabled</span>
                                                        </p>
                                                        <hr />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-6 mb-4">
                                                            <div className="text-center">
                                                                <p>Scan QR Code</p>
                                                                <img
                                                                    src={secretData && secretData.imageUrl}
                                                                    alt="qr img"
                                                                    className="img-fluid qr_img"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <div>
                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="formBasicEmail"
                                                                >
                                                                    <Form.Label >
                                                                        Your 32 Digit Security Code
                                                                    </Form.Label>
                                                                    <InputGroup className="mb-3">
                                                                        <Form.Control type="text" className="custom_security" value={secretData && secretData.secret} />
                                                                        <InputGroup.Text id="basic-addon1">
                                                                            {/* <p>{<>{(secretData?.secret)?.slice(0, 7)}...{(secretData?.secret)?.slice((secretData?.secret).length - 9, (secretData?.secret)?.length - 1)}<FaCopy className="copy_icon" onClick={copyToClipboard} /></>}</p> */}

                                                                            {/* <FaCopy className="copy_icon"/> */}
                                                                            <i class="fa-solid fa-copy" style={{ fontSize: "20px", cursor: "pointer" }} onClick={copyToClipboard} />
                                                                        </InputGroup.Text>
                                                                    </InputGroup>
                                                                </Form.Group>

                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="formBasicEmail"
                                                                >
                                                                    <Form.Label>
                                                                        Enter 6 Digit 2FA Code
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={code}
                                                                        onChange={handleChange}
                                                                    />
                                                                </Form.Group>
                                                                {
                                                                    secretData && secretData.twoFaStatus == "disabled" &&
                                                                    <Button className="disabled_btn common_green_Btn"
                                                                        onClick={() => {
                                                                            enable2fa()
                                                                        }}
                                                                    >
                                                                        {twoFAStatus(secretData.twoFaStatus, 'button')}
                                                                    </Button>
                                                                    // <button
                                                                    //     className="btn btn-primary text-uppercase py-2 w-100"
                                                                    //     type="button"
                                                                    //     onClick={enableTwoFa}
                                                                    // >
                                                                    //     {loader && <i class="fas fa-spinner fa-spin"></i>}
                                                                    //     {t(twoFAStatus(secretData.twoFaStatus, 'button'))}
                                                                    // </button>
                                                                }
                                                                {secretData && secretData.twoFaStatus == "enabled" &&
                                                                    <Button className="disabled_btn common_green_Btn"
                                                                        onClick={() => {
                                                                            disable2fa()
                                                                        }}
                                                                    >
                                                                        {twoFAStatus(secretData.twoFaStatus, 'button')}
                                                                    </Button>
                                                                    // <button
                                                                    //     className="btn btn-primary text-uppercase py-2 w-100"
                                                                    //     type="button"
                                                                    //     onClick={disableTwoFa}
                                                                    // >
                                                                    //     {loader && <i class="fas fa-spinner fa-spin"></i>}
                                                                    //     {t(twoFAStatus(secretData.twoFaStatus, 'button'))}
                                                                    // </button>
                                                                }
                                                                {/* <Button className="disabled_btn common_green_Btn">
                                                                    Disabled
                                                                </Button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-xl-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
                                                    <div className="notes_content">
                                                        <p className="notes">Notes</p>
                                                        <p>
                                                            Enable Two Factor Authentication as an additional
                                                            security measure, to protect your funds and be
                                                            able to withdraw. Please use Google Authenticator
                                                            which you can <a href="#">Download Here</a>
                                                        </p>
                                                    </div>
                                                    <div className="how_to_enable_content">
                                                        <p className="how_to_enabled">How to Enable</p>
                                                        <p>
                                                            Please download the google authenticator by
                                                            clicking "Download here" and scan the QR code .
                                                            You will be receiving a 6 digit code, Which need
                                                            to enter in 2FA box.
                                                            <p>
                                                                Right down the 32 digit code in a white paper
                                                                for your security if you forgot the 2FA code to
                                                                withdraw funds.
                                                            </p>
                                                        </p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="profile" title="Profile">
                                        <div className="row">
                                            <div className="col-xl-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
                                                <div className="two_factor_authentication_content">
                                                    <form className="mt-3">
                                                        <div className="row mt-3">
                                                            <div className="col-12 d-flex align-items-center">
                                                                <p>Current Password</p>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="input_with_eye_pwd">
                                                                    <input
                                                                        onChange={(e) => handlechange(e)}

                                                                        id="password"
                                                                        type={password ? "password" : "text"}
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        className="form-control"
                                                                        value={formValue.password}
                                                                    />
                                                                    <div className="eye_div">
                                                                        {password ? (
                                                                            <i
                                                                                class="fa fa-eye"
                                                                                aria-hidden="true"
                                                                                onClick={() => {
                                                                                    setPassword(false)
                                                                                }}
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                class="fa fa-eye-slash"
                                                                                aria-hidden="true"
                                                                                onClick={() => {
                                                                                    setPassword(true)
                                                                                }}
                                                                            ></i>
                                                                        )}
                                                                    </div>


                                                                </div>
                                                                <span className="text-danger">{validateError.password}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-12 d-flex align-items-center">
                                                                <p>New Password</p>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="input_with_eye_pwd">
                                                                    <input
                                                                        onChange={(e) => handlechange(e)}
                                                                        id="newpassword"
                                                                        type={newpassword ? "password" : "text"}
                                                                        name="newpassword"
                                                                        placeholder="newpassword"
                                                                        className="form-control"
                                                                        value={formValue.newpassword}
                                                                    />
                                                                    <div className="eye_div">
                                                                        {newpassword ? (
                                                                            <i
                                                                                class="fa fa-eye"
                                                                                aria-hidden="true"
                                                                                onClick={() => {
                                                                                    setNewPassword(false)
                                                                                }}
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                class="fa fa-eye-slash"
                                                                                aria-hidden="true"
                                                                                onClick={() => {
                                                                                    setNewPassword(true)
                                                                                }}
                                                                            ></i>
                                                                        )}
                                                                    </div>


                                                                </div>
                                                                <span className="text-danger">{validateError.newpassword}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-12 d-flex align-items-center">
                                                                <p>Confirm New Password</p>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="input_with_eye_pwd">
                                                                    <input
                                                                        onChange={(e) => handlechange(e)}

                                                                        id="confirmnewpassword"
                                                                        type={confirmnewpassword ? "password" : "text"}
                                                                        name="confirmnewpassword"
                                                                        placeholder="confirmnewpassword"
                                                                        className="form-control"
                                                                        value={formValue.confirmnewpassword}

                                                                    />
                                                                    <div className="eye_div">
                                                                        {confirmnewpassword ? (
                                                                            <i
                                                                                class="fa fa-eye"
                                                                                aria-hidden="true"
                                                                                onClick={() => {
                                                                                    setConfirmNewPassword(false)
                                                                                }}
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                class="fa fa-eye-slash"
                                                                                aria-hidden="true"
                                                                                onClick={() => {
                                                                                    setConfirmNewPassword(true)
                                                                                }}
                                                                            ></i>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                                <span className="text-danger">{validateError.confirmnewpassword}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 authentication_factor">

                                                            <Button className="disabled_btn common_green_Btn btn_style_ena" onClick={() => handleSubmit()}>
                                                                UPDATE

                                                            </Button>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                            <div className="col-xl-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0 mt-3">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h6>Notes:</h6>
                                                        <p>For security measures, your password should contain:</p>
                                                        <ul>
                                                            <li>
                                                                a minimum of 6 characters long
                                                            </li>
                                                            <li>
                                                                a maximum of 18 characters long
                                                            </li>
                                                            <li>
                                                                at least one UPPERCASE letter (A, B, X, Y...)
                                                            </li>
                                                            <li>

                                                                at least one LOWERCASE letter (a,b,x,y...)
                                                            </li>
                                                            <li>

                                                                at least one digit (0,1,2,9...)
                                                            </li>
                                                            <li>
                                                                at least one special character (! @ # $ ^ *...)
                                                            </li>
                                                        </ul>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                </Tabs>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            {/* Deposit */}
            {/* <Modal
            className="qr_code_modal"
            centered
            show={Qrshow}
            onHide={handleCloseQr}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bitcoin</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="deposit_modal">
                <div className="d-flex justify-content-center align-items-center">
                  <QRCode
                    title="GeeksForGeeks"
                    value={walletData.walletAddress}
                    // bgColor={back}
                    // fgColor={fore}
                    // size={size === '' ? 0 : size}
                  />
                </div>
                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <ul>
                    <li>Lorem Ipsum is simply dummy text.</li>
                    <li>Lorem Ipsum is simply dummy text.</li>
                    <li>Lorem Ipsum is simply dummy text.</li>
                  </ul>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseQr} className="common_green_Btn">
                Done
              </Button>
            </Modal.Footer>
          </Modal> */}

            {/* Withdraw */}
            {/* <Modal
            className="withdraw_modal"
            centered
            show={withdrawshow}
            onHide={handleCloseWithdraw}
          >
            <Modal.Header closeButton>
              <Modal.Title>Withdraw</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="withdraw_content">
                <div className="d-flex justify-content-between align-items-center">
                  <span>TO Address</span>
                  <input
                    type="text"
                    className="custom_input"
                    name="address"
                    id="address"
                    value={formValue.address}
                    onChange={(e) => handlechange(e)}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <span>Amount</span>
                  <input
                    type="text"
                    className="custom_input"
                    name="amount"
                    id="amount"
                    value={formValue.amount}
                    onChange={(e) => handlechange(e)}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseWithdraw} className="common_green_Btn">
                Done
              </Button>
            </Modal.Footer>
          </Modal> */}
        </div>
    );
    // import { useSelector, useDispatch } from "react-redux";
    // import { key } from "../config/config";
    // import qrcode from "../Assets/qrcode.png";
    // import QRCode from "react-qr-code";
    // import Tab from "react-bootstrap/Tab";
    // import Tabs from "react-bootstrap/Tabs";
    // import Form from "react-bootstrap/Form";
    // import InputGroup from "react-bootstrap/InputGroup";
    // import { FaCopy } from "react-icons/fa";

    // function SecurityContent() {
    //   const [Qrshow, setQrShow] = useState(false);
    //   const [formValue, setFormValue] = useState({});
    //   const handleShowQr = () => setQrShow(true);
    //   const handleCloseQr = () => setQrShow(false);

    //   const [withdrawshow, setWithdrawShow] = useState(false);

    //   const handleShowWithdraw = () => setWithdrawShow(true);
    //   const handleCloseWithdraw = () => setWithdrawShow(false);

    //   const { walletData } = useSelector((state) => state.wallet);
    //   // console.log( "userssssData" ,walletData.assets)

    //   const { currencyDetails } = useSelector((state) => state.currency);
    //   console.log("userssssData", currencyDetails);

    //   const handlechange = (e) => {
    //     var { name, value } = e.target;
    //     setFormValue((formValue) => ({ ...formValue, [name]: value }));
    //   };

    //   return (
    //     <div className="security-settings">
    //       <h3 className="my-3">Security Settings</h3>
    //       <div className="row">
    //         <div className="col-xl-12 mb-3">
    //           <Card className="settings_card">
    //             <Card.Body className="">
    //               <div className="tab_content_sec">
    //                 <Tabs
    //                   defaultActiveKey="home"
    //                   id="uncontrolled-tab-example"
    //                   className="mb-3"
    //                 >
    //                   <Tab eventKey="home" title="2 Step Authentications">
    //                     <div className="inside_content authentication_factor">
    //                       <div className="row">
    //                         <div className="col-xl-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
    //                           <div className="two_factor_authentication_content">
    //                             <p className="enabled_status">
    //                               2 Factor Authentication{" "}
    //                               <span className="status">Status</span> -{" "}
    //                               <span className="enabled">Enabled</span>
    //                             </p>
    //                             <hr />
    //                           </div>
    //                           <div className="row">
    //                             <div className="col-xl-6 mb-4">
    //                               <div className="text-center">
    //                                 <p>Scan QR Code</p>
    //                                 <img
    //                                   src={qrcode}
    //                                   alt="qr img"
    //                                   className="img-fluid qr_img"
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="col-xl-6">
    //                               <div>
    //                                 <Form.Group
    //                                   className="mb-3"
    //                                   controlId="formBasicEmail"
    //                                 >
    //                                   <Form.Label >
    //                                     Your 32 Digit Security Code
    //                                   </Form.Label>
    //                                   <InputGroup className="mb-3">
    //                                     <Form.Control type="text" className="custom_security" />
    //                                     <InputGroup.Text id="basic-addon1">
    //                                       <FaCopy className="copy_icon"/>
    //                                     </InputGroup.Text>
    //                                   </InputGroup>
    //                                 </Form.Group>

    //                                 <Form.Group
    //                                   className="mb-3"
    //                                   controlId="formBasicEmail"
    //                                 >
    //                                   <Form.Label>
    //                                     Enter 6 Digit @FAC Code
    //                                   </Form.Label>
    //                                   <Form.Control type="text" />
    //                                 </Form.Group>
    //                                 <Button className="disabled_btn common_green_Btn">
    //                                   Disabled
    //                                 </Button>
    //                               </div>
    //                             </div>
    //                           </div>
    //                         </div>
    //                         <div className="col-xl-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
    //                           <div className="notes_content">
    //                             <p className="notes">Notes</p>
    //                             <p>
    //                               Enable Two Factor Authentication as an additional
    //                               security measure, to protect your funds and be
    //                               able to withdraw. Please use Google Authenticator
    //                               which you can <a href="#">Download Here</a>
    //                             </p>
    //                           </div>
    //                           <div className="how_to_enable_content">
    //                             <p className="how_to_enabled">How to Enable</p>
    //                             <p>
    //                               Please download the google authenticator by
    //                               clicking "Download here" and scan the QR code .
    //                               You will be receiving a 6 digit code, Which need
    //                               to enter in 2FA box.
    //                               <p>
    //                                 Right down the 32 digit code in a white paper
    //                                 for your security if you forgot the 2FA code to
    //                                 withdraw funds.
    //                               </p>
    //                             </p>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </Tab>
    //                   {/* <Tab eventKey="profile" title="Profile">
    //                     Tab content for Profile
    //                   </Tab> */}
    //                 </Tabs>
    //               </div>
    //             </Card.Body>
    //           </Card>
    //         </div>
    //       </div>

    //       {/* Deposit */}
    //       <Modal
    //         className="qr_code_modal"
    //         centered
    //         show={Qrshow}
    //         onHide={handleCloseQr}
    //       >
    //         <Modal.Header closeButton>
    //           <Modal.Title>Bitcoin</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //           <div className="deposit_modal">
    //             <div className="d-flex justify-content-center align-items-center">
    //               <QRCode
    //                 title="GeeksForGeeks"
    //                 value={walletData.walletAddress}
    //                 // bgColor={back}
    //                 // fgColor={fore}
    //                 // size={size === '' ? 0 : size}
    //               />
    //             </div>
    //             <div className="mt-4 d-flex justify-content-center align-items-center">
    //               <ul>
    //                 <li>Lorem Ipsum is simply dummy text.</li>
    //                 <li>Lorem Ipsum is simply dummy text.</li>
    //                 <li>Lorem Ipsum is simply dummy text.</li>
    //               </ul>
    //             </div>
    //           </div>
    //         </Modal.Body>
    //         <Modal.Footer>
    //           <Button onClick={handleCloseQr} className="common_green_Btn">
    //             Done
    //           </Button>
    //         </Modal.Footer>
    //       </Modal>

    //       {/* Withdraw */}
    //       <Modal
    //         className="withdraw_modal"
    //         centered
    //         show={withdrawshow}
    //         onHide={handleCloseWithdraw}
    //       >
    //         <Modal.Header closeButton>
    //           <Modal.Title>Withdraw</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //           <div className="withdraw_content">
    //             <div className="d-flex justify-content-between align-items-center">
    //               <span>TO Address</span>
    //               <input
    //                 type="text"
    //                 className="custom_input"
    //                 name="address"
    //                 id="address"
    //                 value={formValue.address}
    //                 onChange={(e) => handlechange(e)}
    //               />
    //             </div>
    //             <div className="d-flex justify-content-between align-items-center mt-4">
    //               <span>Amount</span>
    //               <input
    //                 type="text"
    //                 className="custom_input"
    //                 name="amount"
    //                 id="amount"
    //                 value={formValue.amount}
    //                 onChange={(e) => handlechange(e)}
    //               />
    //             </div>
    //           </div>
    //         </Modal.Body>
    //         <Modal.Footer>
    //           <Button onClick={handleCloseWithdraw} className="common_green_Btn">
    //             Done
    //           </Button>
    //         </Modal.Footer>
    //       </Modal>
    //     </div>
    //   );
    // }
}

export default SecurityContent;
