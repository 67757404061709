export const getAuthToken = () => {
    if (localStorage.getItem('admin_token')) {
        return localStorage.getItem('admin_token')
    }
    return '';
}

export const setAuthToken = (token) => {
    if (token) {
        localStorage.setItem("admin_token", token);
    }
}

export const SetWallAddress = (account, wallet) => {
    sessionStorage.setItem('accountInfo', account)
    if (wallet != "") {
        sessionStorage.setItem('wallet', wallet)
    }
}
export const getWalletAddress = () => {
    j
    if (sessionStorage.getItem('accountInfo')) {
        return sessionStorage.getItem('accountInfo')
    }
    return '';
}
export const removeWallteAddress = () => {
    if (sessionStorage.getItem('accountInfo')) {
        sessionStorage.removeItem('accountInfo')
        sessionStorage.removeItem('wallet')
    }
    return '';
}