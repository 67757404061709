// import constant
import {
    profile_Data,
    ISAUTH,
    USER_PLAN,
    SET_THEME,
    REFERENCE_CODE,
    NOTICE_DATA
} from '../constant';

const initialValue = {
    isAuthenticated: false,
    userDetails: {},
    userPlan: {},
    isAuth: false,
    referenceCode:''
}

//  console.log("initialValue----->>>",initialValue);
const account = (state = initialValue, action) => {

    switch (action.type) {
        case ISAUTH:
            return {
                ...state,
                isAuth: action.payload
            }
        case profile_Data:
            return {
                ...state,
                userDetails: action.payload
            };
        case USER_PLAN:
            return {
                ...state,
                userPlan: action.payload
            };
        case REFERENCE_CODE:
            return {
                ...state,
                referenceCode: action.payload
            };
        case NOTICE_DATA:
            return {
                ...state,
                noticeData: action.payload
            };
            
        case SET_THEME:
            return {
                ...state,
                ...{
                    theme: action.theme
                }
            };
        default:
            return state;
    }
}

export default account;