import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function DepositNotfiy(props) {
    const { showModal, handleCloseModal ,currencySymbol } = props
    // const { walletData } = useSelector((state) => (state.wallet))
    const history = useHistory()
    return (
        <>
            <Modal
                className="purchase_bot_modal"
                centered
                show={showModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Deposit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Don't have enough balance to buy this plan . Please make a deposit for {currencySymbol} </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} className="common_green_Btn">
                        Close
                    </Button>
                    <Button onClick={() => {
                        history.push('/wallet')
                    }} className="common_green_Btn">
                        Ok
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DepositNotfiy;
