import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { IoMdNotifications } from "react-icons/io";
import { IconName } from "react-icons/io5";
import { BiSolidUser } from "react-icons/bi";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHeader from "./DashboardHeader";
import TradeHistoryContent from "./TradeHistoryContent";
import WalletContent from "./WalletContent";
import { readNotification, getNotification, readsingelNotification } from '../api/api';
import { dateTimeFormat, momentFormat } from '../lib/dateTimeHelper';

function Notification() {
  const [sidebar, setSidebar] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const handleClose = () => {
    setSidebar(!sidebar);
  };

  const updateDimensions = () => {
    // setWidth(window.innerWidth);
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
  };
  const fetchNotificationHistory = async () => {
    try {
      const { status, loading, ReadMsg } = await getNotification();
      if (status) {
        console.log("read", ReadMsg)
        setData(ReadMsg)
      }
    } catch (err) { }
  }
  useEffect(() => {
    fetchNotificationHistory()
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  const readMess = async (id) => {
    let data = { id: id }
    console.log("id-----------------------", id);
    let { staus, message } = await readsingelNotification(data);
    fetchNotificationHistory()
    console.log("status----------", staus);
    //noticePopup(dispatch, false);

  };


  const readAllMsg = async () => {
    let { result, message } = await readNotification();

    setData(result)
    console.log("result----", result);
    // noticePopup(dispatch, false);
  };
  return (
    <div className="dashboard notification">
      <div className="container-fluid d-flex ps-0 ">
        <div className="left_side_sec">
          <DashboardSidebar handleClose={()=>{handleClose()}} sidebar={sidebar} />
        </div>
        <div className="right_side_sec px-4 py-4">
          <DashboardHeader handleClose={()=>handleClose()} />
          <p>Notification</p>
          <div className="text-end">
            <a href="javascript:void(0)" className="notification_link_italic" onClick={(e) => { readAllMsg() }}>Mark all as read</a>

          </div>

          <div className="border_div_noti newnotify mt-4">
            <ul className="ps-0">
              {data && data?.length > 0 ? (
                <>
                  {console.log("datadatadatadata_data", data)}
                  {data.map((val, index) => (
                    <li className={val.isRead ? 'read list_chet' : 'unread list_chet'} onClick={(e) => { readMess(val._id) }}>
                      <div className="d-flex align-items-center pl-sm-2 flex_mobi_not">
                        <div>
                          <p className="f-12 lighttxt">{val.description}</p>
                          <p className="text-muted f-12 mb-0">{momentFormat(val.createdAt, 'YYYY-MM-DD HH:mm')}</p>
                        </div>
                        <button className="btn btn-link ml-auto text-capital f-12 py-0 pr-2 shrink-0 pl-0 pl-sm-2" onClick={(e) => { readMess(val._id) }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M374.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7 86.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"></path></svg>
                          Mark as read
                        </button>


                      </div>
                    </li>
                  ))}




                </>



              ) : (<p className='text-center mt-4'>There is no data</p>)}
              {/* <li className="unread list_chet">
                <div className="d-flex align-items-center pl-sm-2 flex_mobi_not">
                  <div>
                    <p className="f-12 lighttxt">Login Successfully</p>
                    <p className="text-muted f-12 mb-0">2023-11-30 15:10</p>
                  </div>
                  <button className="btn btn-link ml-auto text-capital f-12 py-0 pr-2 shrink-0 pl-0 pl-sm-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M374.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7 86.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"></path></svg>
                    Mark as read
                  </button>
                </div>
              </li>

              <li className="read list_chet">
                <div className="d-flex align-items-center pl-sm-2 flex_mobi_not">
                  <div>
                    <p className="f-12 lighttxt">Login Successfully</p>
                    <p className="text-muted f-12 mb-0">2023-11-30 15:10</p>
                  </div>
                  <button className="btn btn-link ml-auto text-capital f-12 py-0 pr-2 shrink-0 pl-0 pl-sm-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M374.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7 86.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"></path></svg>
                    Mark as read
                  </button>
                </div>
              </li> */}
            </ul>
          </div>


        </div>
      </div>
    </div>
  );
}

export default Notification;
